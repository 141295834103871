



















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
import { apiDistributionOrdersLists } from '@/api/distribution/distribution'
@Component({
    components: {
        LsPagination,
        LsDialog,
        DatePicker,
        ExportData
    }
})
export default class DistributionGoods extends Vue {
    /** S Data **/

    apiDistributionOrdersLists = apiDistributionOrdersLists

    // 分销订单列表
    distributionList: any = []

    // 搜索分销订单表单
    goodsSearchData = {
        nickname: '',
        name: '',
        status: '',
        start_time: '',
        end_time: '',
        
    }

    // 佣金状态选择
    options = [
        { value: '', label: '全部' },
        { value: '1', label: '待结算' },
        { value: '2', label: '已入账' },
        { value: '3', label: '已失效' }
    ]

    // 分页
    pager = new RequestPaging()

    /** E Data **/

    /** S Method **/

    // 获取分销列表
    getDistributionData(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiDistributionOrdersLists,
            params: {
                ...this.goodsSearchData,
                ...{ scene: 1 }
            }
        })
    }

    // 详情列表
    detail(row: any) {
        console.log("row", row)
    }

    // 重置
    resetgoodsSearchData() {
        Object.keys(this.goodsSearchData).map(key => {
            this.$set(this.goodsSearchData, key, '')
        })
        this.getDistributionData()
    }

    /** E Method **/

    created() {
        this.getDistributionData()
    }
}
